import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { port } from "../../App";

const GetGeoLocation = () => {
    let empId = JSON.parse(sessionStorage.getItem('dasid'))
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [error, setError] = useState(null);
    const [distance, setDistance] = useState(null);
    const [isInsideOffice, setIsInsideOffice] = useState(false);

    const officeLocation = { latitude: 12.928787, longitude: 77.5853766 }; // Replace with your office location
    const radius = 50; // 50 meters radius

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371000; // Earth's radius in meters
        const toRadians = (degree) => (degree * Math.PI) / 180;

        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distance in meters
    };

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log('Accuracy:', position.coords.accuracy, 'meters');

                    if (position.coords.accuracy > 100) {
                        setError("Location accuracy is too low. Please try again.");
                        return;
                    }
                    const userLatitude = position.coords.latitude;
                    const userLongitude = position.coords.longitude;

                    setLocation({
                        latitude: userLatitude,
                        longitude: userLongitude,
                    });

                    const calculatedDistance = calculateDistance(
                        userLatitude,
                        userLongitude,
                        officeLocation.latitude,
                        officeLocation.longitude
                    );

                    setDistance(calculatedDistance);
                    setIsInsideOffice(calculatedDistance <= radius);
                    setError(null);
                },
                (error) => {
                    // Handle errors...
                },
                { enableHighAccuracy: true }
            );
        } else {
            setError("Geolocation is not supported by this browser.");
        }
    };


    useEffect(() => {
        getLocation();
    }, []);
    let trackTiming = () => {
        if (isInsideOffice) {
            axios.post(`${port}/root/lms/AttendanceRecordCreate?emp_id=${empId}`).then((response) => {
                toast.success('Timing has been recorded')
            }).catch((error) => {
                console.log(error);
            })
        } else {
            toast.warning(`Your away from office location like ${distance && distance.toFixed(2)} meters, try it once reached the office`)
        }
    }
    return (
        <div>
            {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
            <button onClick={trackTiming}
                className={` ${isInsideOffice ? 'bg-green-500 ' : ' bg-red-500 '} p-2 rounded text-slate-100 `} >
                In/Out
            </button>
            {/* {!error && location.latitude && location.longitude && (
                <>

                    <p>Current Latitude: {location.latitude}</p>
                    <p>Current Longitude: {location.longitude}</p>
                    <p>Distance from Office: {distance ? `${distance.toFixed(2)} meters` : "Calculating..."}</p>
                    <p>
                        Status:{" "}
                        <strong style={{ color: isInsideOffice ? "green" : "red" }}>
                            {isInsideOffice ? "Inside Office" : "Outside Office"}
                        </strong>
                    </p>
                </>
            )} */}
        </div>
    );
};

export default GetGeoLocation;
